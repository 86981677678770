/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import {
  Content,
  Header,
  InfoCard,
  Link,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../packages/app/src/store/types';
import {
  AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME,
  AWS_WORKLOAD_ACCOUNT_CREATION_FORM_DESCRIPTION,
  APPROVER_EMAILS,
  ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MIN_LENGTH,
  ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MAX_LENGTH,
  AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK,
  PROD_APPLICATION_ACCOUNTS_OU,
  NON_PROD_APPLICATION_ACCOUNTS_OU,
  USER_GROUPS_FOR_ACCOUNT_CREATION,
} from '../../../../../packages/app/src/common/constants';
import {
  AsynchronousAutoComplete,
  BreadBoardAutoComplete,
  BreadBoardButtonGroup,
  EnvironmentOptions,
} from '../common';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  setApplicationAccounts,
  setSSOPermissionSetsUsers,
} from '../../../../../packages/app/src/store/actions';
import {
  fetchAccountData,
  fetchSSOPermissionSetsUsers,
  validateUserEmail,
  validateDescription,
  validateAccountName,
  triggerRequest,
  BUTTON_COLOR,
  Environments,
  SDLC_STAGES,
  Request,
  AWSAccountCreationRequest,
} from '../../internal';

export const WorkloadAccountRequestForm = () => {
  const dispatch = useDispatch();

  const catalogApi = useApi(catalogApiRef);
  const accessToken = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);

  useEffect(() => {
    if (accessToken) {
      fetchAccountData(accessToken).then(response => {
        dispatch(setApplicationAccounts(response.applicationAccounts));
      });
      fetchSSOPermissionSetsUsers(accessToken).then(response => {
        dispatch(setSSOPermissionSetsUsers(response));
      });
    }
  }, [dispatch, accessToken]);

  const applicationAccounts = useSelector(
    (state: State) => state.applicationAccounts,
  );

  const ssoPermissionSetsUsers = useSelector(
    (state: State) => state.ssoPermissionSetsUsers,
  );

  const [ownerValue, setOwnerValue] = useState<string>('');
  const [groupValue, setGroupValue] = useState<string>('');
  const [otherAccountUsers, setOtherAccountUsersValue] = useState<string[]>([
    '',
  ]);
  const [accountNameValue, setAccountNameValue] = useState<string>('');
  const [accountNameValidation, setAccountNameValidation] =
    useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [descriptionValidation, setDescriptionValidation] =
    useState<boolean>(false);
  const [ownerValueValidation, setOwnerValueValidation] =
    useState<boolean>(false);
  const [otherAccountUsersValidation, setOtherAccountUsersValidation] =
    useState<boolean[]>([false]);
  const [approver, setApprover] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    otherAccountUsersValidationMessage,
    setOtherAccountUsersValidationMessage,
  ] = useState<string[]>(['']);

  const environments: Environments = {
    Option1: {
      value: SDLC_STAGES.DEV,
      label: SDLC_STAGES.DEV,
      isVisible: true,
    },
    Option2: {
      value: SDLC_STAGES.QA,
      label: SDLC_STAGES.QA,
      isVisible: true,
    },
    Option3: {
      value: SDLC_STAGES.STAGE,
      label: SDLC_STAGES.STAGE,
      isVisible: true,
    },
    Option4: {
      value: SDLC_STAGES.UAT,
      label: SDLC_STAGES.UAT,
      isVisible: true,
    },
    Option5: {
      value: SDLC_STAGES.PROD,
      label: SDLC_STAGES.PROD,
      isVisible: true,
    },
  };

  const [envValue, setEnv] = useState<string>(environments.Option1.value); // Setting it to let to change envValue

  const onEnvChange = (event: any) => setEnv(event.target.value);

  const getOU = (env: string) => {
    if (env === 'prod') {
      return PROD_APPLICATION_ACCOUNTS_OU;
    }
    return NON_PROD_APPLICATION_ACCOUNTS_OU;
  };

  const getUserDetails = async (emailID: string) => {
    const user_details = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'spec.profile.email': emailID,
      },
    });
    return user_details;
  };

  const onDescriptionChange = (event: any) => {
    setDescriptionValidation(!validateDescription(event.target.value));
    setDescription(event.target.value);
  };

  const onAccountNameChange = (event: any) => {
    const accountNameWithoutEnv = event.target.value;

    setAccountNameValidation(
      !validateAccountName(
        accountNameWithoutEnv,
        envValue,
        ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MIN_LENGTH,
        ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MAX_LENGTH,
        applicationAccounts,
      ),
    );
    setAccountNameValue(accountNameWithoutEnv);
  };

  const onOwnerChange = async (event: any) => {
    const ownerEmail = event.target.value.toLowerCase();

    setOwnerValue(ownerEmail);

    if (ownerEmail.length >= 1) {
      if (validateUserEmail(ownerEmail, ssoPermissionSetsUsers)) {
        setOwnerValueValidation(false);

        const user_details = await getUserDetails(ownerEmail);
        const groupOptionArr: string[] = [];
        if (user_details.items[0] && user_details.items[0].relations) {
          for (const item of user_details.items[0].relations) {
            const elements = item.targetRef.split('/');
            groupOptionArr.push(elements[elements.length - 1]);
          }
        }
      } else {
        setOwnerValueValidation(true);
      }
    } else {
      setOwnerValueValidation(false);
    }
  };

  const handleChange = async (index: number, event: any) => {
    const accountEmail = event.target.value.toLowerCase();

    const updatedOtherAccountEmailValidation: boolean[] = [
      ...otherAccountUsersValidation,
    ];
    const updatedOtherAccountUsersValidationMessage: string[] = [
      ...otherAccountUsersValidationMessage,
    ];

    if (!validateUserEmail(accountEmail, ssoPermissionSetsUsers)) {
      updatedOtherAccountEmailValidation[index] = true;
      updatedOtherAccountUsersValidationMessage[index] =
        'Enter Valid Email with analog.com domain OR  User not part of AWS SSO.';
    } else if (accountEmail === ownerValue && accountEmail !== '') {
      updatedOtherAccountUsersValidationMessage[index] =
        "Account Owner email can't be added for other account users.";
      updatedOtherAccountEmailValidation[index] = true;
    } else {
      updatedOtherAccountEmailValidation[index] = false;
      updatedOtherAccountUsersValidationMessage[index] = '';
    }

    const updatedOtherAccountUsers: string[] = [...otherAccountUsers];
    updatedOtherAccountUsers[index] = accountEmail;
    setOtherAccountUsersValue(updatedOtherAccountUsers);

    setOtherAccountUsersValidationMessage(
      updatedOtherAccountUsersValidationMessage,
    );
    setOtherAccountUsersValidation(updatedOtherAccountEmailValidation);
  };

  const handleAddFields = () => {
    setOtherAccountUsersValue([...otherAccountUsers, '']);
    setOtherAccountUsersValidation([...otherAccountUsersValidation, false]);
  };

  const handleDeleteFields = (index: number) => {
    if (otherAccountUsers.length > 1) {
      const updatedOtherAccountUsers = [...otherAccountUsers];
      updatedOtherAccountUsers.splice(index, 1);
      setOtherAccountUsersValue(updatedOtherAccountUsers);

      const updatedOtherAccountEmailValidation: boolean[] = [
        ...otherAccountUsersValidation,
      ];
      updatedOtherAccountEmailValidation.splice(index, 1);
      setOtherAccountUsersValidation(updatedOtherAccountEmailValidation);

      const updatedOtherAccountUsersValidationMessage: string[] = [
        ...otherAccountUsersValidationMessage,
      ];
      updatedOtherAccountUsersValidationMessage.splice(index, 1);
      setOtherAccountUsersValidationMessage(
        updatedOtherAccountUsersValidationMessage,
      );
    } else {
      setOtherAccountUsersValue(['']);
      setOtherAccountUsersValidation([false]);
      setOtherAccountUsersValidationMessage(['']);
    }
  };

  const handleReset = () => {
    setOwnerValue('');
    setGroupValue('');
    setOtherAccountUsersValue(['']);
    setDescription('');
    setApprover('');
    setAccountNameValue('');
    setEnv(environments.Option1.value);
    setIsLoading(false);
    setAccountNameValidation(false);
    setDescriptionValidation(false);
    setOwnerValueValidation(false);
    setOtherAccountUsersValidation([false]);
    setOtherAccountUsersValidationMessage(['']);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const stringOtherAccountUsers = otherAccountUsers.toString();

    const accountNameWithEnv = `${accountNameValue} ${envValue}`;

    const requestMessage = `Request: Create a AWS Application Account<br />
    Requester: ${email}<br />
    Environment: ${envValue}<br />
    AWS Account Name: ${accountNameWithEnv}<br />
    Account Owner: ${ownerValue}<br />
    Group: ${groupValue}<br />
    Description: ${description}<br />
    `;

    const content: AWSAccountCreationRequest = {
      requester: email,
      name: accountNameWithEnv,
      email: '',
      owner: ownerValue,
      costApprover: approver,
      otherAccountUsers: stringOtherAccountUsers,
      group: groupValue,
      environment: envValue.toLowerCase(),
      description: description,
      ou: getOU(envValue),
    };

    const request: Request = {
      requestType: AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: content,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME,
      `Request sent for approval to cost approver: '${approver.toLowerCase()}'.
        You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);
    handleReset();
  };

  const submitCheck = () => {
    let canSubmit =
      isLoading ||
      !accountNameValue ||
      !ownerValue ||
      !groupValue ||
      !approver ||
      !description ||
      descriptionValidation ||
      ownerValueValidation ||
      accountNameValidation;

    for (const accountValidity of otherAccountUsersValidation) {
      if (accountValidity) {
        canSubmit = canSubmit || true;
      }
    }
    return canSubmit;
  };

  return (
    <Page themeId="service">
      <Header
        title={AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME}
        subtitle={AWS_WORKLOAD_ACCOUNT_CREATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <EnvironmentOptions
                  label="Environment"
                  value={envValue}
                  onChange={onEnvChange}
                  environments={environments}
                />
                <br />

                <TextField
                  label="Account Name"
                  variant="outlined"
                  onChange={onAccountNameChange}
                  value={accountNameValue}
                  error={accountNameValidation}
                  helperText={
                    accountNameValidation
                      ? 'Entered account name is invalid or is not unique, i.e. similar account already exists!'
                      : ''
                  }
                  required
                  fullWidth
                />
                <FormHelperText id="account-name">
                  The name should be in title case, E.g. For "My New Account"
                  account name and Dev env, "My New Account Dev" will be
                  created.
                  <br />
                  The account name must be at least{' '}
                  {ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MIN_LENGTH} characters long
                  and cannot exceed{' '}
                  {ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MAX_LENGTH} characters. For
                  more information please see{' '}
                  <Link to={AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>
                <br />

                <TextField
                  label="Account Owner (Email Id)"
                  variant="outlined"
                  value={ownerValue}
                  required
                  onChange={onOwnerChange}
                  error={ownerValueValidation}
                  helperText={
                    ownerValueValidation
                      ? 'Enter valid email with analog.com domain or user not part of AWS SPX SSO.'
                      : ''
                  }
                />
                <FormHelperText id="account-owner">
                  Please enter the account owner's email address, ensuring it
                  uses the @analog.com domain. Additionally, confirm that the
                  user is part of the AWS SSO Group. For more information please
                  see{' '}
                  <Link to={AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>

                {otherAccountUsers.map((data, index) => (
                  <>
                    <br />
                    <Stack key={index} spacing={4} direction="row">
                      <TextField
                        error={otherAccountUsersValidation[index]}
                        fullWidth
                        label="Other Account Users"
                        placeholder="Other Account Users"
                        variant="outlined"
                        onChange={(event: any) => handleChange(index, event)}
                        value={data}
                        helperText={otherAccountUsersValidationMessage[index]}
                      />

                      <ButtonGroup
                        variant="contained"
                        color={BUTTON_COLOR.PRIMARY}
                      >
                        {otherAccountUsers.length > 1 && (
                          <Button
                            aria-label="delete"
                            onClick={() => handleDeleteFields(index)}
                          >
                            <DeleteIcon />
                          </Button>
                        )}
                        <Button
                          aria-label="add"
                          onClick={() => handleAddFields()}
                        >
                          <AddIcon />
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </>
                ))}
                <FormHelperText id="callback-url-helper-text">
                  Please enter the email address of a co-member, ensuring it
                  uses the @analog.com domain. Additionally, confirm that the
                  user is part of the AWS SSO Group. For more information please
                  see{' '}
                  <Link to={AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  id="group"
                  options={USER_GROUPS_FOR_ACCOUNT_CREATION}
                  groupBy={(option: any) => option[0]}
                  getOptionLabel={(option: any) => option}
                  value={groupValue}
                  onChange={(_event: any, newValue: any) => {
                    setGroupValue(newValue);
                  }}
                  label="Group"
                />
                <FormHelperText id="group">
                  Please select the group/project associated with this account,
                  for example, Anvil. For more information or what if my group
                  doesn’t display on the list? please see{' '}
                  <Link to={AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>
                <br />

                <BreadBoardAutoComplete
                  id="cost-approver"
                  options={APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) => {
                    setApprover(newValue);
                  }}
                  label="Cost Approver's Email"
                />
                <FormHelperText id="cost-approver-helper-text">
                  Please choose an approver who will authorise the creation of
                  this account.
                </FormHelperText>
                <br />

                <TextField
                  label="Description"
                  variant="outlined"
                  onChange={onDescriptionChange}
                  value={description}
                  required
                  multiline
                  error={descriptionValidation}
                  helperText={
                    descriptionValidation
                      ? "MaxLength:256. Value can only contain alphanumeric characters, spaces, and any of the following characters: _.,:/=+@-'"
                      : ''
                  }
                />
                <FormHelperText id="description">
                  What is the description for this AWS account? E.g. the
                  intended purpose of this AWS account.
                  <br />
                  The description can contain upto 256 alphanumeric characters,
                  spaces, and any of the following characters: _.:/=+@-
                </FormHelperText>
                <br />
              </FormControl>

              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
